
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAILURE = 'ORDER_DETAILS_FAILURE'
export const ORDER_DETAILS_RESET = 'ORDER_DETAILS_RESET'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAILURE = 'ORDER_PAY_FAILURE'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_LIST_OF_USER_REQUEST = 'ORDER_LIST_OF_USER_REQUEST'
export const ORDER_LIST_OF_USER_SUCCESS = 'ORDER_LIST_OF_USER_SUCCESS'
export const ORDER_LIST_OF_USER_FAILURE = 'ORDER_LIST_OF_USER_FAILURE'
export const ORDER_LIST_OF_USER_RESET = 'ORDER_LIST_OF_USER_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE'

export const ORDER_SHIP_REQUEST = 'ORDER_SHIP_REQUEST'
export const ORDER_SHIP_SUCCESS = 'ORDER_SHIP_SUCCESS'
export const ORDER_SHIP_FAILURE = 'ORDER_SHIP_FAILURE'
export const ORDER_SHIP_RESET = 'ORDER_SHIP_RESET'