export const PRODUCT_LIST_REQUEST= 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS= 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAILURE= 'PRODUCT_LIST_FAILURE'

export const PRODUCT_CATEGORY_LIST_REQUEST= 'PRODUCT_CATEGORY_LIST_REQUEST'
export const PRODUCT_CATEGORY_LIST_SUCCESS= 'PRODUCT_CATEGORY_LIST_SUCCESS'
export const PRODUCT_CATEGORY_LIST_FAILURE= 'PRODUCT_CATEGORY_LIST_FAILURE'
export const PRODUCT_CATEGORY_LIST_RESET=   'PRODUCT_CATEGORY_LIST_RESET'

export const PRODUCT_DETAILS_REQUEST= 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS= 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAILURE= 'PRODUCT_DETAILS_FAILURE'
export const PRODUCT_DETAILS_RESET= 'PRODUCT_DETAILS_RESET'

export const PRODUCT_DELETE_REQUEST= 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS= 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAILURE= 'PRODUCT_DELETE_FAILURE'

export const PRODUCT_IMAGE_DELETE_REQUEST= 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_IMAGE_DELETE_SUCCESS= 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_IMAGE_DELETE_FAILURE= 'PRODUCT_DELETE_FAILURE'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAILURE = 'PRODUCT_CREATE_FAILURE'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAILURE = 'PRODUCT_UPDATE_FAILURE'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAILURE = 'PRODUCT_CREATE_REVIEW_FAILURE'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAILURE = 'PRODUCT_TOP_FAILURE'