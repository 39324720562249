export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'
export const USER_SIGNOUT = 'USER_SIGNOUT'

export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE'

export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_FAILURE = 'USER_DETAILS_FAILURE'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAILURE = 'USER_DELETE_FAILURE'

export const USER_ADMIN_UPDATE_REQUEST = 'USER_ADMIN_UPDATE_REQUEST'
export const USER_ADMIN_UPDATE_SUCCESS = 'USER_ADMIN_UPDATE_SUCCESS'
export const USER_ADMIN_UPDATE_FAILURE = 'USER_ADMIN_UPDATE_FAILURE'
export const USER_ADMIN_UPDATE_RESET = 'USER_ADMIN_UPDATE_RESET'

export const GUEST_FALSE = 'GUEST_FALSE'
export const GUEST_TRUE = 'GUEST_TRUE'
export const GUEST_FAILURE = 'GUEST_FAILURE'
export const GUEST_RESET = 'GUEST_RESET'
