export const BASKET_ADD_ITEM='BASKET_ADD_ITEM'
export const BASKET_REMOVE_ITEM='BASKET_REMOVE_ITEM'
export const BASKET_CLEAR_ITEMS = 'BASKET_RESET'
export const BASKET_SAVE_SHIPPING_ADDRESS = 'BASKET_SAVE_SHIPPING_ADDRESS'
export const BASKET_SAVE_GUEST_INFO = 'BASKET_SAVE_GUEST_INFO'
export const BASKET_SAVE_PAYMENT_METHOD = 'BASKET_SAVE_PAYMENT_METHOD'
export const BASKET_RESET='BASKET_RESET'
export const BASKET_CLEAR_ADDRESS='BASKET_CLEAR_ADDRESS'
export const BASKET_CLEAR_ITEMS_PRICE='BASKET_CLEAR_ITEMS_PRICE'
export const BASKET_CLEAR_TAX_PRICE='BASKET_CLEAR_TAX_PRICE'
export const BASKET_CLEAR_GUEST_INFO='BASKET_CLEAR_GUEST_INFO'
export const BASKET_CLEAR_SHIPPING_PRICE='BASKET_CLEAR_SHIPPING_PRICE'
export const BASKET_CLEAR_TOTAL_PRICE='BASKET_CLEAR_TOTAL_PRICE'
export const BASKET_CLEAR_AFTER_ORDER='BASKET_CLEAR_AFTER_ORDER'